import { ActionTypes } from "../constants/action-types";

const initalState = {
	tasks: [],
};

export const taskManagerReducer = (state = initalState, { type, payload }) => {
	switch (type) {
		case ActionTypes.SET_TASKS:
			return { ...state, tasks: payload };

		default:
			return state;
	}
};
